// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Hero from "../components/homepage/hero-section/hero"
import Layout from "../components/layout/layout"
import TestimonySection from "../components/homepage/testimony-section/TestimonySection"
import EquipeExperts from "../components/homepage/equipe-experts"
import PlateformeSaaS from "../components/homepage/plateformeSaaS"
import MapSection from "../components/homepage/mapSection"
import ServiceCardsSection from "../components/homepage/cards-section"
import CardsSlider from "../components/homepage/cardsSlider"
import TalkAboutUs from "../components/homepage/talkAboutUs"
import PublicationsSection from "../components/homepage/publications-section"
import { blogTags } from "../utils/siteConfig"

const Index = ({ data, pageContext }) => {
  const { allContentfulFooter } = data
  const langKey = pageContext.langKey

  const posts = data.allGhostPost.edges
    .map(item => item.node)
    .filter(post =>
      blogTags.some(blogTag => post.tags.map(tag => tag.slug).includes(blogTag))
    )
    .filter(post => post.tags.map(tag => tag.slug).includes(langKey))
    .slice(0, 3)

  return (
    <>
      <Helmet>
        <title>
          Seelk - Amazon Retail Media Analytics Software & Consultancy
        </title>
        <meta
          name="description"
          content="Gagnez la bataille sur Amazon: des experts et un logiciel unique pour augmenter vos performances E-commerce"
        />
        <meta
          name="google-site-verification"
          content="LudhNmgegkz4lv6K7vnfASUF48b6XEKIZtAe2DVwtiU"
        />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
      <Layout
        isDarkMode
        langKey={langKey}
        allContentfulFooter={allContentfulFooter}
      >
        <div
          sx={{
            display: "block",
            gridTemplateColumns: "1fr",
            gridGap: 4,
          }}
        >
          <Hero langKey={langKey} />
          <TestimonySection langKey={langKey} />
          <EquipeExperts langKey={langKey} />
          <PlateformeSaaS langKey={langKey} />
          <MapSection langKey={langKey} />
          <ServiceCardsSection langKey={langKey} />
          <PublicationsSection />
          <CardsSlider langKey={langKey} blogContent={posts} />
          <TalkAboutUs langKey={langKey} />
        </div>
      </Layout>
    </>
  )
}

export default Index

export const data = graphql`
  query GhostPostIndexEnQuery($langKey: String!) {
    allGhostPost(sort: { order: DESC, fields: [published_at] }) {
      edges {
        node {
          ...GhostPostFields
          featureImageSharp {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { node_locale: { eq: $langKey }, agencyServices: { ne: null } }
    ) {
      edges {
        node {
          node_locale
          seelkOffices {
            officeCity
            officeAddress
            officeTelephoneNumber
          }
          saasForAmazon
          agencyServices
          aboutSeelk
          copyrights
          contact
          connect
        }
      }
    }
  }
`
